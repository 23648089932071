<template>
  <div>
    <PageTopNavigation :items="topNav" item-width="w-32" />
    <router-view />
  </div>
</template>

<script>
import PageTopNavigation from "@/components/atom/PageTopNavigation";
import data from "@/data/communication/data";

export default {
  data() {
    return {
      topNav: data.topNavigation
    };
  },
  components: {
    PageTopNavigation
  }
};
</script>
