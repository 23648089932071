
const interactionTabNavigation = [
    {id: 1, icon: 'list', name: 'Create List', path: 'createList'},
    {id: 2, icon: 'account_box', name: 'Import/Export', path: 'importExport'},
    {id: 3, icon: 'campaign', name: 'Campaign', path: 'campaign'},
    {id: 4, icon: 'miscellaneous_services', name: 'Automation', path: 'automation'},
    {id: 5, icon: 'wysiwyg', name: 'Form', path: 'interactionForm'},
    {id: 6, icon: 'trending_up', name: 'Deal', path: 'deal'},
    {id: 7, icon: 'bar_chart', name: 'Report', path: 'report'},
];

const popupTabNavigation = [
    {id: 1, icon: 'list', name: 'Notice', path: 'notice'},
    {id: 2, icon: 'account_box', name: 'Forum', path: 'forum'},
    {id: 3, icon: 'campaign', name: 'Suggestion', path: 'suggestion'},
    {id: 4, icon: 'miscellaneous_services', name: 'Feedback', path: 'feedback'},
    {id: 5, icon: 'wysiwyg', name: 'Blog Share', path: 'blogShare'}
];

const leadsTabNavigation = [
    {id: 1, icon: 'list', name: 'Online & Blog', path: 'onlineAndBlog'},
    {id: 2, icon: 'account_box', name: 'Trial Class Students', path: 'trialClassStudent'},
    {id: 3, icon: 'campaign', name: 'Expired Students', path: 'expiredStudents'},
    {id: 4, icon: 'miscellaneous_services', name: 'Seminars', path: 'seminars'},
    {id: 5, icon: 'wysiwyg', name: 'Webinars', path: 'webinars'},
    {id: 6, icon: 'trending_up', name: 'Workshop', path: 'workshop'}
];




const topNavigation = [
    { icon: "flaticon-reading-book", label: "Interaction", path: "interaction" },
    { icon: "flaticon-reading-book", label: "Popup Creation", path: "popupCreation" },
    { icon: "flaticon-reading-book", label: "Leads", path: "leads" },
  ];


const communications = [];

for (let i = 0; i < 15; i++) {
  communications.push({
    id: i,
    name: 'Cristiano Ronaldo',
    designation: 'Student',
    branch: 'Dhanmondi',
    course: 'EXP. Listening',
    emailAddress: 'ronaldo@football.com',
    contact: '+880171229558',
    addedBy: 'Kazi Shahin'
  });
}

export default {
    interactionTabNavigation,
    leadsTabNavigation,
    popupTabNavigation,
    topNavigation,
    communications
};
